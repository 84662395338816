export default {
  data() {
    return {
      steps: {}
    };
  },
  computed: {
    stepList() {
      const stepsArr = Object.entries(this.steps);
      return stepsArr.reduce((result, [key, { valid, num, title, icon }]) => {
        if (!this.tabs.includes(key)) return result;
        const step = { valid, sort: num, name: title };
        if (icon) step.icon = icon;
        result.push(step);
        return result;
      }, [])
        .sort(({sort: a}, {sort: b}) => a - b);
    }
  }
};

