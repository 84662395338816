<template>
  <div class="page-profile">
    <div class="container">
      <div class="page-profile__head">
        <h1 class="page-title">{{ title }}</h1>
        <div class="page-text">{{ subTitle }}</div>
      </div>
      <div class="row row--lg">
        <div class="col-12 col-md-4 col-lg">
          <slot name="nav"></slot>
        </div>
        <div class="col-12 col-md-8 col--lg">
          <slot name="content"></slot>
          <div class="page-profile__btn-gp">
            <slot name="footer-btns">
              <button @click="$emit('exit')" type="button" class="btn btn--border">Выйти из редактирования</button>
              <button @click="$emit('save')" type="button" class="btn btn--prime">Сохранить изменения</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
  name: 'ProfilePageWrapper',
};
</script>
<style lang="scss" scoped>

// page-profile
.page-profile {
  padding: 80px 0 30px;
  .page-profile__head {
    margin-bottom: 30px;
    @include respond-to('sm') {
      text-align: center;
    }
  }
  .page-profile__btn-gp {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    .btn {
      width: 100%;
      max-width: 343px;
      margin-bottom: 20px;
    }
  }
  @include respond-to('sm') {
    padding: 100px 0 30px;
    .page-profile__head {
      margin-bottom: 40px;
    }
    .page-profile__btn-gp {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 30px;
      .btn {
        width: 48%;
        max-width: 343px;
        margin-bottom: 0px;
      }
    }
  }
  @include respond-to('md') {
    padding: 120px 0 30px;
    .page-profile__head {
      margin-bottom: 50px;
    }
  }
}

</style>
