export const defaultIdentification = {
  serial: '',
  number: '',
  image1: null,
  image2: null,
};

export const defaultCompany = {
  legal_form: {
    name: '',
    country: null,
  },
  docs: {
    inn: '',
    ogrn: '',
    inn_img: null,
    ogrn_img: null,
  },
  name: '',
  staff_num: '',
  region: '',
};
