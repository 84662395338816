<template>
  <v-form class="profile-form">
    <div class="profile-form__block">
      <div class="profile-form__head">
        <h4 class="profile-form__title">Банковский счет</h4>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 mb--sm">
          <TextInput
            label="Счет получателя*"
            only-numbers
          />
        </div>
        <div class="col-12 col-md-6 mb--sm">
          <TextInput
            label="Корреспондентский счет*"
            only-numbers
          />
        </div>
        <div class="col-12 col-md-6 mb--sm">
          <TextInput
            label="Наименование Банка*"
          />
        </div>
        <div class="col-12 col-md-6 mb--sm">
          <TextInput
            label="ОГРН*"
            only-numbers
          />
        </div>
        <div class="col-12 col-md-6 mb--sm">
          <TextInput
            label="БИК*"
            only-numbers
          />
        </div>
        <div class="col-12 col-md-6 mb--sm">
          <TextInput
            label="КПП*"
            only-numbers
          />
        </div>
        <div class="col-12 col-md-6 mb--sm">
          <TextInput
            only-numbers
            label="ИНН"
          />
        </div>
      </div>
      <div class="bank-details" v-if="false">
        <button class="bank-details__btn" type="button">
          <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="15" cy="15" r="14.5" stroke="#B3B3B8"/><circle cx="8" cy="15" r="2" fill="#000"/><circle cx="15" cy="15" r="2" fill="#000"/><circle cx="21" cy="15" r="2" fill="#000"/></svg>
        </button>
        <div class="bank-details__item">
          <p class="bank-details__title">Счет получателя</p>
          <p class="bank-details__text"></p>
        </div>
        <div class="bank-details__item">
          <p class="bank-details__title">Корреспондентский счет</p>
          <p class="bank-details__text"></p>
        </div>
        <div class="bank-details__item">
          <p class="bank-details__title">Наименование Банка</p>
          <p class="bank-details__text"></p>
        </div>
        <div class="bank-details__item">
          <p class="bank-details__title bank-details__title--upper">Бик</p>
          <p class="bank-details__text"></p>
        </div>
        <div class="bank-details__item">
          <p class="bank-details__title bank-details__title--upper">ОГРН</p>
          <p class="bank-details__text"></p>
        </div>
        <div class="bank-details__item">
          <p class="bank-details__title bank-details__title--upper">КПП</p>
          <p class="bank-details__text"></p>
        </div>
        <div class="bank-details__item">
          <p class="bank-details__title bank-details__title--upper">Инн</p>
          <p class="bank-details__text"></p>
        </div>
      </div>
    </div>
  </v-form>
</template>

<script>

import TextInput from "@component/UI/Form/TextInput";

export default {
  components: {
    TextInput,
  },
  data() {
    return {
      formData: {

      },
    };
  },
  methods: {

  },
  mounted() {
    this.$emit('update:valid', true);
  }
};

</script>

<style lang="scss" scoped>

// bank-details
.bank-details {
  position: relative;
  background: #EDEDF4;
  border-radius: 14px;
  margin-bottom: 25px;
  padding: 28px 0;
  .bank-details__btn {
    position: absolute;
    top: 20px;
    right: 23px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: transparent;
    border: none;
    padding: 0;
  }
  .bank-details__item {
    padding: 8px 23px 8px 35px;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    &:last-child {
      border-bottom: none;
    }
    .bank-details__title {
      font-size: 17px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.4);
      margin-bottom: 10px;
      &--upper {
        text-transform: uppercase;
      }
    }
    .bank-details__text {
      font-size: 19px;
      line-height: 22px;
      color: #000000;
    }
  }
}

</style>
