export default {
  methods: {
    uploadPreview(file) {
      return new Promise((resolve, reject) => {
        const FR = new FileReader();

        FR.onloadend = () => {
          resolve(FR.result);
        };

        FR.onerror = () => {
          reject('#');
        };

        FR.readAsDataURL(file);
      });
    },
  }
};
