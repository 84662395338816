<template>
  <v-form
    class="modal-service"
    v-model="valid"
  >
    <div class="modal-service__main">
      <div class="modal-service__title">{{ title }}</div>
      <div class="modal-service__text">{{ text }}</div>
      <div class="field-ell--hidden">
        <Select
          label="Выберите категорию услуги"
          :items="categories"
          item-value="id"
          item-text="name"
          v-model="formData.category"
          required
        />
      </div>
      <div class="field-ell--hidden">
        <Select
          label="Выберите услугу"
          :items="services"
          item-value="id"
          item-text="name"
          v-model="formData.responsibility"
          required
        />
      </div>

      <button
        type="button"
        class="btn btn--prime"
        @click="submit"
        :disabled="!valid"
      >Сохранить</button>
    </div>
  </v-form>
</template>

<script>

import Select from '@component/UI/Form/Select.vue';
import {mapState} from "vuex";

export default {
  name: "ModalServiceProvide",
  components: {
    Select,
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      }
    },
    title: String,
    text: String,
    resolve: {
      type: Function,
      default: () => false
    }
  },
  computed: {
    ...mapState({
      categories: state => state.enums.categories,
    }),

    services() {
      const category = this.categories.find(category => category.id === this.formData.category);
      return category?.responsibility ?? [];
    }
  },
  data() {
    return {
      valid: false,
      formData: {
        category: '',
        responsibility: ''
      }
    };
  },
  methods: {
    submit() {
      this.$emit('submit', this.formData);
    }
  },
  mounted() {
    if (Object.keys(this.data).length) {
      const {
        id,
        responsibility: {
          id: responsibility
        },
        category: { id: category },
      } = this.data;

      this.formData = {
        id,
        category,
        responsibility
      };

    }
  }
};
</script>

<style lang="scss" scoped>

::v-deep {
  .v-input {
    .v-select__selections {
      font-size: 19px;
    }
  }
}

// modal-evaluate
.modal-service {
  width: 100%;
  max-width: 400px;
  min-width: 400px;
  @media(max-width: 767.98px) {
    min-width: auto;
  }
  .modal-service__main {
    padding: 45px 26px 34px 26px;
  }
  .modal-service__sub {
    padding: 20px 26px 34px;
  }
  .modal-service__title {
    margin-bottom: 14px;
    color: #000000;
    font-weight: 600;
    font-size: 19px;
    text-align: center;
  }
  .modal-service__text {
    margin-bottom: 22px;
    color: rgba(0, 0, 0, 0.4);
    font-size: 17px;
    line-height: 1.3;
    text-align: center;
  }
  .modal-service__bl {
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(0,0,0,.2);
    .av-radio {
      margin-bottom: 14px;
      .av-radio__vue {
        cursor: default;
      }
    }
  }
  .modal-service__next {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    padding: 8px 0;
    color: rgba(0, 0, 0, 0.4);
    font-size: 15px;
    cursor: pointer;
    .modal-service__next-label {
      color: #000000;
      font-size: 19px;
    }
    svg {
      margin-left: 4px;
      transform: rotateZ(-90deg);
    }
  }
  .modal-service__head {
    position: relative;
    padding: 22px 24px;
    color: #000000;
    font-weight: 600;
    font-size: 19px;
    text-align: center;
    border-bottom: 1px solid rgba(0,0,0,.2);
    .modal-service__back {
      position: absolute;
      left: 22px;
      top: 26px;
      width: 20px;
      height: auto;
      transform: rotateZ(90deg);
      cursor: pointer;
      path {
        fill: var(--color-prime);
      }
    }
  }
  .modal-service__recipient {
    margin-bottom: 30px;
    .av-radio {
      margin-bottom: 20px;
    }
  }
  .modal-service__list {
    margin-bottom: 30px;
  }
  .btn {
    margin-top: 20px;
    width: 100%;
  }
}

</style>
