<template>
  <v-form
    ref="form"
    @input="$emit('update:valid', $event)"
    class="profile-form"
  >
    <div class="upload-ava">
      <div class="upload-ava__content">
        <div class="upload-ava__avatar">

          <img
            v-if="!previewImage"
            src="@img/user-avatar.svg"
            class="upload-ava__img"
            alt=""
          >

          <img
            v-else
            :src="previewImage"
            class="upload-ava__img"
            alt=""
          >

        </div>
        <div class="upload-ava__desc">Пожалуйста, загрузите реальную фотографию, она используется для подтверждения аккаунта</div>
      </div>
      <div class="upload-ava__field">
        <input type="file" class="upload-ava__input" @change="upload($event.target.files)">
        <button type="button" class="btn btn--prime">Добавить фото</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 mb--sm">

        <TextInput
          v-model="formData.first_name"
          only-letters
          label="Имя"
          required
        />

      </div>
      <div class="col-12 col-md-6 mb--sm">

        <TextInput
          label="Фамилия"
          only-letters
          v-model="formData.last_name"
          required
        />

      </div>

      <div
        v-if="'email' in data"
        class="col-12 col-md-6 mb--sm"
      >

        <TextInput
          label="E-mail"
          v-model="formData.email"
          is-email
        />

      </div>

      <div class="col-12 col-md-6 mb--sm">

        <Select
          label="Пол"
          item-text="value"
          item-value="key"
          :items="genders"
          v-model="formData.gender"
          required
        />

      </div>
      <div class="col-12 col-md-6 mb--sm">
        <!--        TODO: Сделать проверку заполненое поле: Дата Рождения -->
        <DatePicker
          label="Дата рождения"
          v-model="formData.birthday"
          required
        />

      </div>
      <div class="col-12 col-md-6 mb--sm">

        <TextInput
          :label="labelAbout"
          v-model="formData.about"
        />

      </div>
    </div>
  </v-form>
</template>

<script>

import TextInput from "@component/UI/Form/TextInput";
import Select from "@component/UI/Form/Select";
import DatePicker from "@component/UI/Form/DatePicker";
import uploadPreview from "@mixin/uploadPreview";
import formData from "@mixin/formData";
import { mapState } from "vuex";

export default {
  components: {
    TextInput,
    DatePicker,
    Select
  },
  props: {
    isRegistrationStep: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [uploadPreview, formData],
  computed: {
    ...mapState({
      genders: state => state.enums.genders,
      user: state => state.user.user,
    }),
    isCustomer() {
      return this.$route.name.includes('NEED'); // !this.user?.helper_mode;
    },
    isCompany() {
      return this.$route.name.includes('COMPANY'); //!this.user?.company?.name;
    },
    labelAbout() {
      if (this.isCustomer) {
        return 'Ваш комментарий (пожелания к заказу)';
      } else {
        if (this.isCompany) {
          return 'Ваш комментарий';
        } else {
          return 'О себе';
        }
      }
      // return this.isCustomer
      //   ? 'Ваш комментарий (пожелания к заказу)'
      //   : this.isCompany
      //     ? 'Ваш комментарий'
      //     : 'О себе';
    },
    previewImage() {
      return this.preview ? this.preview : this.formData.photo;
    }
  },
  data() {
    return {
      preview: null,
    };
  },
  methods: {
    async upload(files) {
      this.formData.photo = files.item(0);
      this.preview = await this.uploadPreview(this.formData.photo);
    },
  },
};

</script>

<style lang="scss" scoped>

</style>
