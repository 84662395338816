<template>
  <ProfilePageWrapper
    @save="saveUserProfileData"
    @exit="closeEditProfile"
    :title="pageTitle"
    sub-title="Укажите данные представителя организации"
  >
    <template #nav>
      <NavSteps
        :list="stepList"
        :active="tab"
        v-on:choose="changeTab($event)"
        :mode="isEditProfileMode ? 'iconList' : undefined"
      />
    </template>
    <template #content>
      <template v-if="tab === 0">
        <DataPerson
          :valid.sync="steps.general.valid"
          :data.sync="steps.general.data"
          is-registration-step
        />
      </template>
      <template v-else-if="tab === 1">

        <DocumentsPerson
          :valid.sync="steps.documents.valid"
          :data.sync="steps.documents.data"
        />

      </template>
      <template v-else-if="tab === 2">

        <DataLegal
          :valid.sync="steps.company.valid"
          :data.sync="steps.company.data"
        />

      </template>
      <template v-else-if="tab === 3">

        <ServicesProvide
          :valid.sync="steps.services.valid"
          :data.sync="steps.services.data"
        />

      </template>
      <template v-else-if="tab === 4">

        <Characteristics
          :valid.sync="steps.characteristics.valid"
          :data.sync="steps.characteristics.data"
        />

      </template>
      <template v-else-if="tab === 5">

        <PaymentBankLegal
          :valid.sync="steps.bank.valid"
        />

      </template>
      <v-dialog v-model="dialogAccept">
        <v-card>
          <ModalAccept title="Изменения сохранены" v-on:confirm="dialogAccept = false" />
        </v-card>
      </v-dialog>
    </template>
    <template v-if="!isEditProfileMode" #footer-btns>
      <template v-if="tab === 0">
        <router-link :to="{ name: 'ASSISTANCE_PROVIDE_SELECT' }" class="btn btn--border">Назад</router-link>
      </template>
      <template v-if="tab > 0">
        <button type="button" class="btn btn--border" @click="changeTab(--tab)">Назад</button>
      </template>
      <template v-if="tab < 5">
        <button type="button" class="btn btn--prime" :disabled="!valid" @click="changeTab(++tab)">Далее</button>
      </template>
      <template v-if="tab === 5">
        <button
          type="button"
          class="btn btn--prime"
          @click="onRegister"
          :disabled="loading || !validAllForms"
        >
          <template v-if="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </template>

          <template v-else>Завершить</template>
        </button>
      </template>
    </template>
  </ProfilePageWrapper>
</template>

<script>
import NavSteps from '@component/Module/NavSteps';
import DataPerson from '@component/Page/Profile/DataPerson';
import DataLegal from '@component/Page/Profile/DataLegal';
import DocumentsPerson from '@component/Page/Profile/DocumentsPerson';
import ServicesProvide from '@component/Page/Profile/ServicesProvide';
import Characteristics from '@component/Page/Profile/Characteristics';
import PaymentBankLegal from '@component/Page/Profile/PaymentBankLegal';
import ProfilePageWrapper from '@component/Page/Profile/ProfilePageWrapper.vue';
import { mapState } from 'vuex';
import ModalAccept from '@component/Modal/ModalAccept';
import UserEditModel from '@entity/UserEditModel.js';
import confirmLeave from "@mixin/confirmLeave";
import stepList from "@mixin/Register/stepList";
import validation from "@mixin/Register/validation";

// const getTabsList = mode => {
//   const tabs = ['general', 'documents', 'company', 'services', 'characteristics', 'bank'];
//   if (mode === 'editProfile') {
//     tabs.splice(2, 0, 'activity');
//   }
//   return tabs;
// };
export default {
  components: {
    NavSteps,
    DataPerson,
    DataLegal,
    DocumentsPerson,
    ServicesProvide,
    Characteristics,
    PaymentBankLegal,
    ProfilePageWrapper,
    ModalAccept,
  },
  mixins: [confirmLeave, stepList, validation],
  props: {
    mode: {
      type: String,
      default: 'auth',
      validator(value) {
        return ['auth', 'editProfile'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      tab: 0,
      currentForm: false,
      loading: false,
      steps: {
        general: {
          valid: false,
          num: 0,
          title: 'Общие сведения',
          data: null,
          key: 'general',
          icon: require('@img/nav/provide-general.svg')
        },
        documents: {
          valid: false,
          num: 1,
          title: 'Мои документы',
          data: null,
          key: 'documents',
          icon: require('@img/nav/provide-documents.svg')
        },
        company: {
          valid: false,
          num: 2,
          title: 'Данные организации',
          data: null,
          key: 'company',
          icon: require('@img/nav/provide-company.svg')
        },
        services: {
          valid: false,
          num: 4,
          title: 'Мои услуги',
          key: 'services',
          data: {
            services: [],
          },
          icon: require('@img/nav/provide-services.svg')
        },
        characteristics: {
          valid: false,
          num: 5,
          title: 'Характеристика',
          key: 'characteristics',
          data: [],
          icon: require('@img/nav/provise-characteristics.svg')
        },
        bank: {
          valid: false,
          num: 6,
          title: 'Банковские данные',
          key: 'bank',
          data: {
            bank_name: '',
            first_name: '',
            iban: '',
            last_name: '',
            organization: '',
            receiver: '',
            swift: '',
          },
          icon: require('@img/nav/provide-bank.svg')
        },
      },
      testData: {
        general: {
          valid: false,
          num: 0,
          title: 'Общие сведения',
          data: {
            photo: null,
            first_name: 'Дядя',
            last_name: 'Петя',
            gender: 'MALE',
            birthday: '14.02.1995',
            commentary: '',
          },
          key: 'general',
        },
        documents: {
          valid: false,
          num: 1,
          title: 'Мои документы',
          data: {
            serial: '1001',
            number: '101010',
            files: [],
          },
          key: 'documents',
        },
        company: {
          valid: false,
          num: 2,
          title: 'Данные организации',
          data: {
            legal_form: {
              name: 'Individual',
              country: 1,
            },
            docs: {
              inn: '123321123',
              ogrn: '567765567',
            },
            name: 'ИП Иванов',
            staff_num: 4,
            region: 1,
            files: [],
          },
          key: 'company',
        },
        activity: {
          valid: false,
          num: 3,
          title: 'Сфера деятельности',
          data: {
            option: 'patron',
          },
          key: 'activity',
        },
        services: {
          valid: false,
          num: 4,
          title: 'Мои услуги',
          key: 'services',
          data: {
            services: [],
          },
        },
        characteristics: {
          valid: false,
          num: 5,
          title: 'Характеристика',
          key: 'characteristics',
          data: [],
        },
        bank: {
          valid: false,
          num: 6,
          title: 'Банковские данные',
          key: 'bank',
          data: {
            bank_name: '',
            first_name: '',
            iban: '',
            last_name: '',
            organization: '',
            receiver: '',
            swift: '',
          },
        },
      },
      sphereActivity: 'Патронажная помощь',
      sphereActivityList: [
        {
          value: 'Патронажная помощь',
          name: 'Патронажная помощь',
          text: 'Услуги пожилым людям и инвалидам. Для профессиональных опекунов',
        },
        {
          value: 'Домашний персонал',
          name: 'Домашний персонал',
          text: 'Услуги по хозяйству, организация досуга. Для владельцев агентств по уходу за домом',
        },
      ],
      tabs: ['general', 'documents', 'company', 'services', 'characteristics', 'bank'],
      dialogAccept: false,
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('enums', ['legalForms']),
    valid() {
      return this.steps[this.tabs[this.tab]].valid;
    },
    isEditProfileMode() {
      return this.mode === 'editProfile';
    },
    pageTitle() {
      return this.isEditProfileMode ? 'Редактирование профиля юридического лица' : 'Юридическое лицо';
    },
  },
  methods: {
    submitForm(formData, key) {
      this.testData[key].data = formData;
    },
    changeTab(tab) {
      this.tab = tab;
      this.currentForm = this.testData[this.tabs[tab]].valid;
    },
    async onRegister() {
      this.loading = true;
      try {
        // const company = {
        //   legal_form: {
        //     name: this.testData.organization.data.legal_form,
        //     country: this.legalForms.find(el => el.name === this.testData.organization.data.legal_form).country
        //   },
        //   name: this.testData.organization.data.name,
        //   staff_num: +this.testData.organization.data.staff_num,
        //   region: +this.testData.organization.data.region,
        //   docs: {
        //     inn: this.testData.organization.data.inn,
        //     inn_img: this.testData.organization.data.docs.inn,
        //     ogrn: this.testData.organization.data.ogrn,
        //     ogrn_img: this.testData.organization.data.docs.ogrn,
        //     is_verified: false
        //   }
        // };
        const sendUser = await RequestManager.Register.sendUser({
          general: {
            ...this.steps.general.data,
            is_registered: true,
            helper_mode: true
          },
          documents: this.steps.documents.data,
          company: this.steps.company.data,
        });
        // const sendDocuments = await RequestManager.Register.uploadDocuments(this.testData.documents.data, me);
        if (sendUser.first_name) {
          this.$store.commit('user/setUser', sendUser);
          // this.$store.dispatch('user/getUser', sendUser);
          this.$router.push({
            name: this.$routeName.ASSISTANCE_PROVIDE_COMPANY_APPLICATIONS,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async saveUserProfileData() {
      const sendUser = await RequestManager.Register.sendUser({
        [this.tabs[this.tab]]: this.steps[this.tabs[this.tab]].data,
      });
      if (sendUser) {
        this.$store.commit('user/setUser', sendUser);
        this.dialogAccept = true;
      }
    },
    closeEditProfile() {
      this.$router.push({
        name: this.$routeName.ASSISTANCE_PROVIDE_COMPANY_APPLICATIONS,
      });
    },
  },
  created() {
    const { general, company, identification: documents } = new UserEditModel(this.user);
    this.steps.general.data = general;
    this.steps.documents.data = documents;
    this.steps.company.data = company;
  },

  mounted() {
    this.country = this.$route.query;

    if (this.isEditProfileMode) {
      this.$set(this.steps.general.data, 'email', '');
    }

    if (this.$route.query._debug === '1') {
      this.$set(this, 'steps', this.testData);
    }
  },
};
</script>
