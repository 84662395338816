<template>
  <v-form
    class="profile-form"
  >
    <div class="profile-form__block">
      <div class="profile-form__head">
        <h4 class="profile-form__title">Фото документов подтверждающие вашу квалификацию</h4>
      </div>
      <UploadDocument
        v-model="formData"
      />
    </div>
  </v-form>
</template>

<script>

import UploadDocument from '@component/Page/Profile/UploadDocument';
import formData from "@mixin/formData";

export default {
  components: {
    UploadDocument,
  },
  mixins: [formData],
  watch: {
    formData(arr) {
      this.$emit('update:valid', true);
    }
  }
};

</script>

<style lang="scss" scoped>

</style>
