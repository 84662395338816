<template>
  <div class="row">
    <div
      class="col-12 col-md-6 mb--md"
      v-for="(document, i) of documents"
      :key="i"
    >

      <div class="upload-document">
        <div class="upload-document__box">

          <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'upload-document__icon-check'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>

          <div class="upload-document__preview">
            <img :src="document.image" class="upload-document__img" alt="">
          </div>

        </div>

        <div class="upload-document__cont">
          <p class="upload-document__descr">{{ document.name }}</p>
        </div>

        <div class="upload-document__remove" @click="remove(i, document.id)">
          <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'upload-document__icon-close'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
        </div>
      </div>

    </div>

    <div class="col-12 col-md-6 mb--md">

      <div class="upload-document upload-document--pn" @click="dialogUpload = true">

        <div class="upload-document__box">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'upload-document__icon-add'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.126 9.165h-4.96v4.96c0 .645-.525 1.165-1.165 1.165a1.17 1.17 0 01-1.165-1.165v-4.96h-4.96A1.166 1.166 0 01.71 8c0-.64.525-1.165 1.165-1.165h4.96v-4.96C6.836 1.23 7.36.71 8 .71s1.165.525 1.165 1.165v4.96h4.96a1.166 1.166 0 010 2.33z" fill="#FAFAFA"/></svg>
        </div>

        <div class="upload-document__cont">
          <p class="upload-document__descr">Добавить документ</p>
        </div>
      </div>

    </div>

    <v-dialog v-model="dialogUpload">
      <v-card>

        <div class="btn-close" @click="dialogUpload = false">
          <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
        </div>

        <ModalUploadFile
          v-if="dialogUpload"
          @submit="submit"
        />

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import ModalUploadFile from "@component/Modal/ModalUploadFile";
import { mapState } from 'vuex';


export default {
  components: {
    ModalUploadFile,
  },
  model: {
    event: 'input',
    prop: 'uploadedDocuments'
  },
  props: {
    uploadedDocuments: Array
  },
  computed: {
    ...mapState({
      categories: state => state.enums.categories,
      user: state => state.user.user
    }),
    documents: {
      get() {
        return this.uploadedDocuments.reduce((result, {category: categoryId, image, id}) => {
          const {name} = this.categories.find(category => category.id === categoryId);
          result.push({
            name,
            image,
            id
          });
          return result;
        }, []);
      },
      set(v) {
        this.$emit('input',
          [
            ...this.uploadedDocuments,
            ...v
          ]
        );
      }
    }
  },
  data() {
    return {
      dialogUpload: false,
    };
  },
  methods: {
    async submit(formData) {
      this.documents = await RequestManager.Certificate.create(this.user.id, formData);
      this.dialogUpload = false;
    },
    remove(i, id) {
      RequestManager.Certificate.remove(this.user.id, id).then(() => {
        const uploadedDocuments = [...this.uploadedDocuments];
        uploadedDocuments.splice(i, 1);
        this.$emit('input', uploadedDocuments);
      });
    }
  },
  async mounted() {
    await this.$store.dispatch('enums/getCategories');
    if (!this.uploadedDocuments.length) {
      this.documents = await RequestManager.Certificate.getList(this.user.id);
    }
  }
};

</script>

<style lang="scss" scoped>

// upload-document
.upload-document {
  position: relative;
  display: flex;
  align-items: center;
  .upload-document__box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    margin-right: 15px;
    border-radius: 50%;
    background-color: #F2F2F7;
    overflow: hidden;
    .upload-document__icon-add {
      position: relative;
      width: 25px;
      height: auto;
      z-index: 2;
      path {
        fill: var(--color-prime);
        transition: fill .2s ease-in-out;
      }
    }
    .upload-document__icon-check {
      position: relative;
      width: 25px;
      height: auto;
      z-index: 2;
      path {
        fill: #35C759;
      }
    }
    .upload-document__preview {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
      }
      .upload-document__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .upload-document__cont {
    .upload-document__descr {
      color: #000000;
      font-size: 17px;
    }
    .upload-document__status {
      color: rgba(0, 0, 0, 0.4);
      font-size: 15px;
      &--green {
        color: #35C759;
      }
      &--red {
        color: #FF6B69;
      }
    }
  }
  .upload-document__remove {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--color-prime);
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-in-out;
    cursor: pointer;
    .upload-document__icon-close {
      width: 10px;
      height: auto;
      path {
        fill: #FFFFFF;
      }
    }
    &:hover {
      background-color: var(--color-prime-active);
    }
  }
  &:hover {
    .upload-document__remove {
      opacity: 1;
      visibility: visible;
    }
  }
  &--pn {
    cursor: pointer;
    &:hover {
      .upload-document__icon-add {
        path {
          fill: var(--color-prime-active);
        }
      }
    }
  }
}

</style>
