<template>
  <v-form
    ref="form"
    class="profile-form"
    v-model="valid"
  >
    <div class="profile-form__block">
      <div class="row">
        <div class="col-12 col-md-6 mb--sm">
          <Select
            label="Страна"
            :items="countries"
            item-value="id"
            item-text="name"
            v-model="formData.legal_form.country"
            required
          />
        </div>
        <div class="col-12 col-md-6 mb--sm">
          <Select
            label="Регион"
            :items="regions"
            item-value="id"
            item-text="name"
            v-model="formData.region"
            :disabled="!formData.legal_form.country"
            required
          />
        </div>
        <div class="col-12 col-md-6 mb--sm">
          <TextInput label="Наименование компании*" v-model="formData.name" required />
        </div>
        <div class="col-12 col-md-6 mb--sm">
          <Select
            label="Организационно-правовая форма"
            :items="legalFormsByCountry"
            item-value="name"
            item-text="name"
            v-model="formData.legal_form.name"
            :disabled="!formData.legal_form.country"
            required
          />
        </div>
        <div class="col-12 col-md-6 mb--sm">
          <TextInput
            label="ИНН*"
            only-numbers
            v-model="formData.docs.inn"
            required
            is-inn
          />
        </div>
        <div class="col-12 col-md-6 mb--sm">
          <TextInput
            label="ОГРН/ОГРНИП"
            only-numbers
            v-model="formData.docs.ogrn"
            required
            is-ogrn-or-ogrnip
          />
        </div>
      </div>
    </div>
    <div class="profile-form__block">
      <div class="profile-form__head">
        <h4 class="profile-form__title">Информация по вашим документам</h4>
      </div>

      <UploadDocument
        :name-list="['Скан ИНН', 'Скан ОГРН/ОГРНИП']"
        v-model="formData.files"
      />
    </div>
  </v-form>
</template>

<script>
import TextInput from '@component/UI/Form/TextInput';
import Select from '@component/UI/Form/Select.vue';
import UploadDocument from '@component/Page/Register/UploadDocument';
import formData from '@mixin/formData';
import { mapState } from 'vuex';

export default {
  components: {
    TextInput,
    Select,
    UploadDocument,
  },
  mixins: [formData],
  computed: {
    ...mapState({
      countries: state => state.enums.countries,
      legalForms: state => state.enums.legalForms,
    }),
    legalFormsByCountry() {
      return this.legalForms.filter(legal => legal.country === this.formData.legal_form.country);
    },
    filesUploaded() {
      if (!this.formData?.files) return false;
      return this.formData.files.length && this.formData.files.every(item => item.preview);
    },
  },
  watch: {
    'formData.legal_form.country': async function (countryId) {
      this.regions = await RequestManager.Enums.getRegions(countryId);
    },
    formData: {
      deep: true,
      handler: function () {
        this.validation();
      }
    }
  },
  updated() {
    this.validation();
  },
  data() {
    return {
      regions: [],
      valid: false
    };
  },
  async created() {
    if (this.formData.region && this.formData.legal_form.country) {
      this.regions = await RequestManager.Enums.getRegions(this.formData.legal_form.country);
    }
  },
  methods: {
    upload(files) {
      this.formData.files.push(files.item(0));
    },
    validation() {
      this.$emit('update:valid', this.valid && this.filesUploaded);
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
