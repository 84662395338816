<template>
  <v-form
    ref="form"
    v-model="valid"
    lass="profile-form"
  >
    <div class="profile-form__block">
      <div class="profile-form__head">
        <h4 class="profile-form__title">Данные паспорта</h4>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 mb--sm">
          <TextInput
            label="Серия"
            only-numbers
            v-model="formData.serial"
            :max="4"
            required
          />
        </div>
        <div class="col-12 col-md-6 mb--sm">
          <TextInput
            only-numbers
            label="Номер"
            v-model="formData.number"
            :max="6"
            required
          />
        </div>
      </div>
    </div>
    <div class="profile-form__block">

      <div class="profile-form__head">
        <h4 class="profile-form__title">Фото паспорта</h4>
      </div>

      <UploadDocument
        v-model="formData.files"
      />
    </div>
  </v-form>
</template>

<script>

import TextInput from "@component/UI/Form/TextInput";
import UploadDocument from '@component/Page/Register/UploadDocument';
import formData from "@mixin/formData";

export default {
  components: {
    TextInput,
    UploadDocument,
  },
  mixins: [formData],
  data() {
    return {
      valid: false
    };
  },
  computed: {
    filesUploaded() {
      if (!this.formData?.files) return false;
      return this.formData.files.length && this.formData.files.every(item => item.preview);
    }
  },
  watch: {
    formData: {
      deep: true,
      handler: function (fieldList) {
        this.validation();
        // Object.entries(fieldList).every(field => {
        //
        // });
      }
    }
  },
  updated() {
    this.validation();
  },
  methods: {
    validation() {
      this.$emit('update:valid', this.valid && this.filesUploaded);
    }
  }

};

</script>

<style lang="scss" scoped>
  .profile-form__title {
    margin-bottom: 25px;
  }
</style>
