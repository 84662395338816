<template>
  <v-form
    ref="form"
    class="profile-form"
  >
    <div class="profile-form__block">

      <div class="profile-form__head">
        <h4 class="profile-form__title">Добавьте ваши услуги</h4>
        <p class="profile-form__text">Выберите типы услуг, которые вам нужны</p>
      </div>

      <button
        type="button"
        class="btn btn--prime btn--md"
        @click="openDialogService()"
      >Добавить услугу</button>

    </div>

    <template v-if="loading">
      <v-progress-linear indeterminate></v-progress-linear>
    </template>

    <template v-else>
      <div class="profile-form__block">
        <div class="profile-form__head">
          <h4 class="profile-form__title">Ваши услуги</h4>
        </div>
        <div class="list-services">
          <template v-for="(service, i) of services">

            <BlockService
              :service="service"
              @edit="openDialogService(i)"
              @remove="removeService(i, service.id)"
              @changeStatus="changeStatus(i, service.id, $event)"
              :key="i"
              :show-details="false"
            />

          </template>
        </div>
      </div>
    </template>
    <v-dialog v-model="dialogService">
      <v-card>
        <div
          class="btn-close"
          @click="closeDialogService"
        >
          <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
        </div>

        <ModalServiceProvide
          v-if="dialogService"
          :data="activeServiceData"
          :title="dialogServiceTitle"
          :text="dialogServiceText"
          @submit="saveService"
        />

      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>

import BlockService from "@component/Module/BlockService";
import ModalServiceProvide from "@component/Modal/ModalServiceProvide";
import formData from '@mixin/formData';
import {mapState} from "vuex";

export default {
  components: {
    BlockService,
    ModalServiceProvide,
  },
  mixins: [formData],
  data() {
    return {
      loading: true,
      dialogService: false,
      activeServiceId: null,
    };
  },
  watch: {
    dialogService(v) {
      if (!v) {
        this.activeServiceId = null;
      }
    },
    services(arr) {
      this.$emit('update:valid', Boolean(arr.length));
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),

    services() {
      return this.formData.services.reduce((result, service) => {
        const {
          id,
          category,
          responsibility,
          is_active
        } = service;

        result.push({
          id,
          category,
          responsibility,
          is_active
        });

        return result;
      }, []);

    },

    activeServiceData() {
      return this.activeServiceId !== null ?
        this.formData.services[this.activeServiceId]
        : {};
    },

    dialogServiceTitle() {
      return this.activeServiceId !== null ?
        'Редактировать'
        : 'Добавить услугу';
    },

    dialogServiceText() {
      return this.activeServiceId !== null ?
        'Вы можете внести изменения'
        : 'Выберите минимум одну услугу';
    }
  },
  methods: {

    changeStatus(i, serviceId, { value, done }) {
      const is_active = value;
      RequestManager.Service.updateService(this.user.id, serviceId, { is_active })
        .then(() => this.formData.services[i].is_active = is_active)
        .finally(() => done());
    },

    async saveService(data) {
      //this.formData.services.push({ ...pl });

      if (this.activeServiceId === null) {

        await RequestManager.Service.createService(this.user.id, data)
          .then(service => {
            this.formData.services.push(service);
          });

      } else {

        const service = await RequestManager.Service.updateService(this.user.id, data.id, data);
        this.$set(this.formData.services, this.activeServiceId, service);
          // .then(response => {
          //   this.formData.services[this.activeServiceId] = response;
          // });
      }
      this.closeDialogService();
    },

    openDialogService(i = null) {
      if (i !== null) this.activeServiceId = i;
      this.dialogService = true;
    },

    closeDialogService() {
      this.dialogService = false;
    },

    removeService(i, serviceId) {
      RequestManager.Service.removeService(this.user.id, serviceId).then(() => {
        this.formData.services.splice(i, 1);
      });
    },

    async getServices() {
      this.formData.services = await RequestManager.Service.getServices(this.user.id);
    }

  },
  async mounted() {
    await this.$store.dispatch('enums/getCategories');
    await this.getServices().finally(() => this.loading = false);
  }
};

</script>

<style lang="scss" scoped>

</style>
