import { defaultCompany, defaultIdentification } from './userEditModelConst.js';

export default class UserEditModel {
  constructor(user) {
    // general
    let { photo = null, first_name = '', last_name = '', gender = '', birthday = '', about = '' } = user;
    this.general = {
      photo: photo || null,
      first_name: first_name || '',
      last_name: last_name || '',
      gender: gender || '',
      birthday: birthday || '',
      about: about || '',
    };

    // identification
    let { identification } = user;
    identification = identification || defaultIdentification;
    let { serial, number, image1, image2 } = identification;
    this.identification = {
      serial: serial || '',
      number: number || '',
      files: [
        {
          file: null,
          name: 'Фото паспорта (2-3 страница)',
          preview: image1 || null,
        },
        {
          file: null,
          name: 'Фото паспорта (4-5 страница)',
          preview: image2 || null,
        },
      ],
    };

    // company
    let { company } = user;
    company = company || defaultCompany;

    let { legal_form, docs, name, staff_num, region } = company;
    this.company = {
      legal_form: {
        name: legal_form.name || '',
        country: legal_form.country || null,
      },
      docs: {
        inn: docs.inn || '',
        ogrn: docs.ogrn || '',
      },
      name: name || '',
      staff_num: staff_num || '',
      region: region || '',
      files: [
        {
          file: null,
          name: 'Скан ИНН',
          preview: docs.inn_img || null,
        },
        {
          file: null,
          name: 'Скан ОГРН/ОГРНИП',
          preview: docs.ogrn_img || null,
        },
      ],
    };
  }
}
