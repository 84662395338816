<template>
  <nav :class="['nav-steps', { 'nav-steps--shown': navShown }]" v-click-outside="hideNav">
    <div class="nav-steps__head" @click="toggleNav">
      <div class="nav-steps__content">
         <div v-if="isIconListMode" class="nav-profile__icon">
          <img :src="activeIcon" alt="">
        </div>
        {{ activeName }}
      </div>
      <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'nav-steps__arr'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.062.065l1.428 1.426-6.494 6.507L.49 1.49 1.917.064l5.078 5.078L12.062.065z" fill="#AEAEB2"/></svg>
    </div>
    <div class="nav-steps__body">
      <template v-for="(item, index) in list">
        <div
          :class="[
            'nav-steps__item',
            {'nav-steps__item--active' : activeIndex === index },
            {'nav-steps__item--passed' : item.valid && !isIconListMode }
          ]"
          :key="item.index"
          @click="choose(index)"
        >
          <div class="nav-steps__content">
            <div :class="isIconListMode ? 'nav-profile__icon' :'nav-steps__icon'">

              <img
                v-if="isIconListMode"
                :src="item.icon"
                alt=""
              >

              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>

            </div>
            {{ item.name }}
          </div>
          <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'nav-steps__arr'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.062.065l1.428 1.426-6.494 6.507L.49 1.49 1.917.064l5.078 5.078L12.062.065z" fill="#AEAEB2"/></svg>
        </div>
      </template>
    </div>
  </nav>
</template>

<script>

export default {
  name: "NavSteps",
  props: {
    list: Array,
    active: Number,
    mode:{
      type: String,
      default: 'checkList',
      validator: function (value) {
        return ['checkList', 'iconList'].indexOf(value) !== -1;
      }
    }
  },
  data() {
    return {
      navShown: false,
    };
  },
  computed: {
    activeIndex() {
      return this.active;
    },
    activeName() {
      return this.list[this.active].name;
    },
    activeIcon() {
      return this.list[this.active].icon;
    },
    isIconListMode(){
      return this.mode === 'iconList';
    },
  },
  methods: {
    choose(index){
      this.navShown = false;
      this.$emit('choose', index);
    },
    hideNav() {
      this.navShown = false;
    },
    toggleNav() {
      this.navShown = !this.navShown;
    }
  }
};
</script>

<style scoped lang="scss">

// nav-steps
.nav-steps {
  position: relative;
  margin-bottom: 20px;
  .nav-steps__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 54px;
    padding: 10px 15px;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid rgba(118, 118, 128, 0.2);
    .nav-steps__content {
      display: flex;
      align-items: center;
      color: #000000;
      font-size: 16px;
      .nav-profile__icon {
        width: 20px;
        margin-right: 12px;
        text-align: center;
        img {
          max-width: 100%;
          height: auto;
          vertical-align: text-bottom;
        }
      }
    }
    .nav-steps__arr {
      width: 14px;
      height: auto;
      transform: rotateZ(-90deg);
      path {
        fill: #000000;
      }
    }
  }
  .nav-steps__body {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    margin-top: 10px;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid rgba(118, 118, 128, 0.2);
    box-shadow: 0 3px 25px rgba(151, 161, 176, 0.25);
    opacity: 0;
    visibility: hidden;
    will-change: transform;
    transform: rotateX(90deg);
    transform-origin: center top;
    transition: all .2s ease-in-out;
    z-index: 10;
  }
  .nav-steps__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 8px 15px;
    border-bottom: 1px solid rgba(118, 118, 128, 0.2);
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    .nav-steps__content {
      display: flex;
      align-items: center;
      color: #B3B3B8;
      font-size: 16px;
      transition: color .2s ease-in-out;
      .nav-steps__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        margin-right: 15px;
        border: 1px solid #C7C7CC;
        border-radius: 50%;
        transition: all .2s ease-in-out;
        svg {
          width: 12px;
          height: auto;
          vertical-align: text-bottom;
          path {
            fill: #ffffff;
          }
        }
      }
      .nav-profile__icon{
        width: 20px;
        margin-right: 12px;
        text-align: center;
        img {
          max-width: 100%;
          height: auto;
          vertical-align: text-bottom;
        }
      }
    }
    .nav-steps__arr {
      display: none;
      width: 16px;
      height: auto;
      opacity: 0;
      transform: rotateZ(-90deg) scale(0.5);
      transition: all .2s ease-in-out;
      path {
        fill: #000000;
      }
    }
    &:hover {
      .nav-steps__content {
        color: #000000;
      }
    }
    &--active {
      .nav-steps__content {
        color: #000000;
      }
      .nav-steps__arr {
        opacity: 1;
        transform: rotateZ(-90deg) scale(1);
      }
    }
    &--passed {
      .nav-steps__content {
        .nav-steps__icon {
          border: 1px solid var(--color-prime);
          background-color: var(--color-prime);
        }
      }
    }
  }
  &--shown {
    .nav-steps__body {
      opacity: 1;
      visibility: visible;
      transform: rotateX(0deg);
    }
  }
  @include respond-to('sm') {
    .nav-steps__head {
      .nav-steps__content {
        font-size: 18px;
        .nav-profile__icon {
          width: 26px;
          margin-right: 14px;
        }
      }
      .nav-steps__arr {
        width: 16px;
      }
    }
    .nav-steps__item {
      .nav-steps__content {
        font-size: 18px;
        .nav-steps__icon {
          margin-right: 14px;
        }
      }
      .nav-steps__arr {
        width: 16px;
      }
    }
  }
  @include respond-to('md') {
    .nav-steps__head {
      display: none;
    }
    .nav-steps__body {
      margin-top: 0;
      box-shadow: none;
      opacity: 1;
      visibility: visible;
      transform: rotateX(0deg);
    }
    .nav-steps__item {
      height: 68px;
      padding: 16px 20px;
      .nav-steps__arr {
        display: block;
      }
    }
  }
}
</style>
