<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="100%"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="value"
        :label="label"
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        readonly
        v-on="on"
        :required="required"
      />
    </template>

    <div class="datepicker-wrapper">
      <div class="datepicker-inner">
        <v-date-picker
          @input="open = false"
          v-model="date"
          locale="ru-Ru"
          full-width
          :first-day-of-week="1"
          :max="revertDate(maxDate)"
          :min="revertDate(minDate)"
        />
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'DatePicker',
  model: {
    event: 'update',
    prop: 'value'
  },
  props: {
    label: String,
    value: String,
    required: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    maxDate: {
      type: [String, undefined],
      default: undefined,
    },
    minDate: {
      type: [String, undefined],
      default: undefined,
    },
  },

  data()  {
    return {
      open: false
    };
  },

  computed: {
    date: {
      get() {
        return this.value && this.revertDate(this.value);
      },
      set(value) {
        this.$emit('update', this.convertDate(value));
      },
    },
    // dateFormatted() {
    //   return this.value;
    // },
  },
  methods: {
    convertDate(date) {
      if (DateTime(date, 'YYYY-MM-DD', true).isValid()) {
        return DateTime(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
      } else {
        return null;
      }
    },
    revertDate(date) {
      if (DateTime(date, 'DD.MM.YYYY', true).isValid()) {
        return DateTime(date, 'DD.MM.YYYY').format('YYYY-MM-DD');
      } else {
        return null;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-picker__title {
    display: none;
  }
  .v-date-picker-header,
  .v-date-picker-table {
    padding-left: 0;
    padding-right: 0;
    height: auto;
  }
  .v-date-picker-table--date {
    th {
      font-family: SF Pro Display, sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.1px;
      color: rgba(60, 60, 67, 0.3);
      @include respond-to('sm') {
        font-size: 15px;
        line-height: 18px;
      }
    }
    .v-btn {
      width: 32px;
      height: 32px;
      font-family: SF Pro Display, sans-serif;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #333;
      @include respond-to('sm') {
        font-size: 20px;
        line-height: 24px;
        width: 40px;
        height: 40px;
      }
    }
  }
}
.datepicker-wrapper {
  background: #FFFFFF;
  width: 268px;
  height: auto;
  padding: 14px 10px 20px;
  border-radius: 8px;
  @include respond-to('sm') {
    width: 399px;
  }
}
</style>
