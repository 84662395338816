export default {
  props: {
    data: [Object, Array]
  },

  computed: {
    formData: {
      get() { return this.data; },
      set(value) {
        this.$emit('update:data', value);
      }
    }
  }
};
