<template>
  <v-form
    class="modal-upload"
    v-model="valid"
  >
    <div class="modal-upload__title">Загрузить документ</div>

    <Select
      label="Выберите категорию"
      :items="categories"
      item-text="name"
      item-value="id"
      v-model="formData.category"
      :required="true"
    />

    <div class="modal-upload__file">
      <div class="upload-file">

        <input
          :id="`upload-file-${_uid}`"
          class="upload-file__field"
          type="file"
          @change="add($event.target.files)"
        />

        <label
          :for="`upload-file-${_uid}`"
          class="btn btn--border"
        >
          Загрузить изображение
        </label>

        <div class="upload-file__preview" v-if="formData.image">

          <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'upload-file__icon-file'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M23.19 2.579h-9.931L11.283.23a.337.337 0 00-.27-.121H3.002a2.226 2.226 0 00-2.21 2.226v14.44A2.226 2.226 0 003.015 19H23.19a2.226 2.226 0 002.223-2.224V4.802A2.226 2.226 0 0023.19 2.58z" fill="#FF6B69"/></svg>

          <div class="upload-file__name">{{ formData.image.name }}</div>

          <div class="upload-file__remove" @click="formData.image = null">
            <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'upload-file__icon-close'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
          </div>

        </div>
      </div>
    </div>
    <button
      type="button"
      class="btn btn--prime"
      :disabled="!activeButton"
      @click="submit"
    >Сохранить</button>
  </v-form>
</template>

<script>

import Select from '@component/UI/Form/Select.vue';
import { mapState } from 'vuex';

export default {
  name: "ModalServiceProvide",
  components: {
    Select,
  },
  data() {
    return {
      valid: false,
      formData: {
        category: null,
        image: null,
      },
    };
  },
  computed: {
    ...mapState({
      categories: state => state.enums.categories
    }),
    activeButton() {
      return this.valid && this.formData.image;
    }
  },
  methods: {
    add(files) {
      const file = files.item(0);

      if (file.type.indexOf('image') < 0) {
        this.$modal.show('ErrorMessage', { text: 'Недопустимый формат файла' });
        return;
      }

      this.formData.image = files.item(0);
    },

    submit() {
      this.$emit('submit', this.formData);
    },

    reset() {
      const formData = Object.entries(this.formData);
      formData.forEach(([property]) => {
        this.formData[property] = null;
      });
    }
  },
  async mounted() {
    await this.$store.dispatch('enums/getCategories');
  }
};
</script>

<style lang="scss" scoped>

input[type=file] {
  display: none;
}

// modal-upload
.modal-upload {
  width: 98vw;
  max-width: 400px;
  padding: 40px 26px 45px;
  .modal-upload__title {
    margin-bottom: 40px;
    color: #000000;
    font-weight: 600;
    font-size: 19px;
    text-align: center;
  }
  .modal-upload__file {
    margin: 14px 0 30px;
  }
  .btn {
    margin-top: 20px;
    width: 100%;
    &:not(disabled) {
      cursor: pointer;
    }
  }
}

// upload-file
.upload-file {
  .upload-file__field {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    .btn {
      margin: 0;
    }
    .upload-file__input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      font-size: 100px;
      opacity: 0;
      &:hover + .btn {
        color: #FFFFFF;
        background-color: var(--color-prime);
      }
    }
  }
  .upload-file__preview {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 40px 10px 0;
    border-bottom: 1px solid rgba(#000000, 0.2);
    margin-top: 20px;
    .upload-file__icon-file {
      margin-right: 14px;
      path {
        fill: var(--color-prime);
      }
    }
    .upload-file__name {
      color: #000000;
      font-size: 19px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .upload-file__remove {
      position: absolute;
      right: 0;
      top: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: rgba(118, 118, 128, 0.12);
      transition: all .2s ease-in-out;
      cursor: pointer;
      .upload-file__icon-close {
        path {
          fill: var(--color-prime);
          transition: all .2s ease-in-out;
        }
      }
      &:hover {
        background-color: var(--color-prime);
        .upload-file__icon-close {
          path {
            fill: #FFFFFF;
          }
        }
      }
    }
  }
}

</style>
