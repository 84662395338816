export default {
  data() {
    return {
      initialStateForm: {}
    };
  },

  computed: {
    isChangedForm() {
      return Object.is(this.steps, this.initialStateForm);
    }
  },

  beforeRouteLeave (to, from, next) {

    if (this.confirmStayInDirtyForm()){
      next(false);
    } else {
      next();
    }
  },

  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },

  mounted() {
    this.initialStateForm = this.steps;
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },

  methods: {
    beforeWindowUnload(e) {
      e.preventDefault();
      e.returnValue = '';
    },

    confirmLeave() {
      return window.confirm('Вы действительно хоитите уйти? У вас есть несохраненные изменения!');
    },

    confirmStayInDirtyForm() {
      return !this.isChangedForm && !this.confirmLeave();
    },

  }

};
